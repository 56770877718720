import gql from "graphql-tag"
import { useQuery, QueryHookOptions } from "@apollo/react-hooks"

import {
  UserEntity,
  UserAccountTypeEntity,
  UserFolderAccessEntity,
  UserHandbookAccessEntity,
  FolderEntity,
  HandbookEntity,
} from "@src/entities"

import useApolloEntity from "./useApolloEntity"

import {
  USER_FOLDER_ACCESS_RIGHTS_FRAGMENT,
  USER_HANDBOOK_ACCESS_RIGHTS_FRAGMENT,
} from "./fragment"
import { ORGANIZATION_FRAGMENT } from "./useOrganization"
import { USER_ACCOUNT_TYPE_FRAGMENT } from "./useUserAccountType"

const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    name
    email
    contactNumber
    isRegistered
    createdAt
    firstName
    lastName
    avatarUrl
    hasSeenAPUMarketing
    isInterestedInAPU
    isRootUser
    isDesignUser
    isContentUser
    isApuUser
    isCSUser
    customView {
      employeeView {
        pinnedColumns
        shownColumns
      }
      collaboratorView {
        pinnedColumns
        shownColumns
      }
    }
    organizationUserRelations {
      location
      teamName
      userAccountType {
        ...UserAccountTypeFragment
      }
    }
    selectedOrganization {
      id
      name
      slug
    }
    userFolderAccesses {
      ...UserFolderAccessFragment
    }
    userHandbookAccesss {
      ...UserHandbookAccessFragment
    }
  }
  ${USER_FOLDER_ACCESS_RIGHTS_FRAGMENT}
  ${USER_ACCOUNT_TYPE_FRAGMENT}
  ${USER_HANDBOOK_ACCESS_RIGHTS_FRAGMENT}
`

export const ME = gql`
  query me {
    me {
      ...UserFragment
      organizationUserRelations {
        location
        teamName
        userAccountType {
          ...UserAccountTypeFragment
        }
      }
      selectedOrganization {
        ...OrganizationFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${ORGANIZATION_FRAGMENT}
  ${USER_ACCOUNT_TYPE_FRAGMENT}
`

export const GET_CURRENT_USER = gql`
  {
    currentUser @client {
      ...UserFragment
      userAccountType {
        ...UserAccountTypeFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${USER_ACCOUNT_TYPE_FRAGMENT}
`

const LOGIN = gql`
  mutation LoginUser($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      id
    }
  }
`

const REGISTER = gql`
  mutation RegisterUser(
    $email: String!
    $name: String!
    $password: String!
    $contact: String!
    $industryType: IndustryType!
    $employeeRange: EmployeeRange!
  ) {
    register(
      data: {
        email: $email
        name: $name
        password: $password
        contactNumber: $contact
        industryType: $industryType
        employeeRange: $employeeRange
      }
    ) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`

const IS_USER_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isUserLoggedIn
  }
`

export const LOG_OUT = gql`
  mutation Logout {
    logout
  }
`
export const UPDATE_DETAIL = gql`
  mutation UpdateAccount($organizationId: Float!, $data: UpdateAccountInput!) {
    updateAccount(organizationId: $organizationId, data: $data) {
      id
    }
  }
`

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data)
  }
`

export const GET_USER_BY_INVITE_TOKEN = gql`
  query GetUserByInviteToken($token: String!) {
    getUserByInviteToken(token: $token) {
      id
      hasMultipleOrganization
      user {
        id
        name
        email
      }
      organization {
        id
        name
      }
      userAccountType {
        id
        accountType
      }
      invitedBy {
        id
        name
      }
      adminLoginMethods {
        method
        buttonUrl
      }
    }
  }
`

export const VERIFY_USER = gql`
  mutation VerifyUser($data: VerifyUserInput!) {
    verifyUser(data: $data) {
      id
      name
      email
      contactNumber
    }
  }
`

export const VERIFY_USER_WITHOUT_FORM = gql`
  mutation VerifyUserWithoutForm($id: Float!, $inviteToken: String!) {
    verifyUserWithoutForm(id: $id, inviteToken: $inviteToken) {
      id
      name
      email
      contactNumber
    }
  }
`

const GET_ONE_TIME_TOKEN = gql`
  mutation GetOneTimeToken($organizationId: Float!) {
    getOneTimeToken(organizationId: $organizationId)
  }
`

export const UPDATE_SELECTED_ORGANIZATION = gql`
  mutation UpdateSelectedOrganization($organizationId: Float!) {
    updateSelectedOrganization(organizationId: $organizationId) {
      id
      name
    }
  }
`

export const INVITE_OWNER = gql`
  mutation InviteOwner($data: InviteOwnerInput!) {
    inviteOwner(data: $data)
  }
`

export const SEND_MAIL_RESET_PASSWORD = gql`
  mutation ResetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data)
  }
`

const CHECK_TOKEN_RESET_PASSWORD = gql`
  mutation ResetPassword($data: CheckTokenInput!) {
    checkToken(data: $data) {
      id
      name
    }
  }
`

const RESET_PASSWORD = gql`
  mutation ResetPassword($data: SetPasswordInput!) {
    setPassword(data: $data) {
      id
      name
    }
  }
`

export const SET_INTEREST_IN_APU = gql`
  mutation SetInterestInAPU($isInterestedInAPU: Boolean!) {
    setInterestInAPU(isInterestedInAPU: $isInterestedInAPU) {
      id
      name
    }
  }
`

export const SET_SEEN_APU_MARKETING = gql`
  mutation SetSeenAPUMarketing($hasSeenAPUMarketing: Boolean!) {
    setSeenAPUMarketing(hasSeenAPUMarketing: $hasSeenAPUMarketing) {
      id
      name
    }
  }
`

export const UPDATE_CUSTOM_VIEW = gql`
  mutation UpdateCustomView($data: UpdateCustomViewInput!) {
    updateCustomView(data: $data)
  }
`

export const SEND_WELCOME_EMAIL_AND_INVITATION = gql`
  mutation SendWelcomeMailAndInvitation(
    $data: SendWelcomeMailAndInvitationInput!
  ) {
    sendWelcomeMailAndInvitation(data: $data)
  }
`

export const GET_OWNERS_IN_ORGANIZATION = gql`
  query GetOwnersByOrganization($organizationId: Float!) {
    getOwnersByOrganization(organizationId: $organizationId) {
      id
      name
      email
      firstName
    }
  }
`

export const GET_ADMIN_LOGIN_METHODS = gql`
  query GetAdminLoginMethods($email: String!) {
    getAdminLoginMethods(email: $email) {
      method
      buttonUrl
    }
  }
`

const useUser = (organizationId?: number) => {
  const refetchQueriesAfterUpdate = [
    {
      query: ME,
      variables: {
        organizationId,
      },
    },
  ]

  const User = useApolloEntity<{
    get: any
    getUserByToken: any
  }>({
    useLazyQuery: {
      get: {
        query: ME,
        extractEntity(data: any): any {
          return data && data.me
        },
      },
      isUserLoggedIn: {
        query: IS_USER_LOGGED_IN,
        extractEntity(data: any): any {
          return data && data.isUserLoggedIn
        },
      },
      getUserByInviteToken: {
        query: GET_USER_BY_INVITE_TOKEN,
        extractEntity(data: any): any {
          return data && data.getUserByInviteToken
        },
      },
    },
    useMutation: {
      login: {
        mutation: LOGIN,
      },
      register: {
        mutation: REGISTER,
      },
      logout: {
        mutation: LOG_OUT,
      },
      update: {
        mutation: LOGIN,
      },
      updateDetail: {
        mutation: UPDATE_DETAIL,
        refetchQueries: refetchQueriesAfterUpdate,
      },
      updatePassword: {
        mutation: UPDATE_PASSWORD,
      },
      verifyUser: {
        mutation: VERIFY_USER,
      },
      verifyUserWithoutForm: {
        mutation: VERIFY_USER_WITHOUT_FORM,
      },
      getOneTimeToken: {
        mutation: GET_ONE_TIME_TOKEN,
      },
      updateSelectedOrganization: {
        mutation: UPDATE_SELECTED_ORGANIZATION,
      },
      inviteOwner: {
        mutation: INVITE_OWNER,
      },
      sendMailResetPassword: {
        mutation: SEND_MAIL_RESET_PASSWORD,
      },
      checkTokenResetPassword: {
        mutation: CHECK_TOKEN_RESET_PASSWORD,
      },
      resetPassword: {
        mutation: RESET_PASSWORD,
      },
    },
  })

  return User
}

export const useCurrentUser = (options?: QueryHookOptions): UserEntity => {
  const { data } = useQuery(GET_CURRENT_USER, options)
  return data ? data.currentUser : null
}

interface UseCurrentUserRightsProps {
  folder?: FolderEntity
  handbook?: HandbookEntity
}

export const useCurrentUserRights = (options?: UseCurrentUserRightsProps) => {
  const { folder, handbook } = options || {}

  const { data } = useQuery(GET_CURRENT_USER)
  const currentUser: UserEntity = data?.currentUser

  if (!currentUser) {
    return {
      userAccountType: {},
      userFolderAccess: {},
      userHandbookAccess: {},
    }
  }

  const userAccountType: UserAccountTypeEntity =
    currentUser?.organizationUserRelations[0]?.userAccountType
  let userFolderAccess: UserFolderAccessEntity = {}
  let userHandbookAccess: UserHandbookAccessEntity = {}

  if (
    folder &&
    currentUser.userFolderAccesses &&
    currentUser.userFolderAccesses.length
  ) {
    userFolderAccess =
      currentUser.userFolderAccesses.find((d) => d.folderId == folder.id) || {}
  }

  if (folder && folder.createdByUserId == currentUser.id) {
    userFolderAccess!.isFolderAdmin = true
  }

  if (currentUser?.userHandbookAccesss?.length) {
    userHandbookAccess =
      currentUser.userHandbookAccesss.find(
        (d) => d.handbookId == handbook?.id
      ) || {}
  }

  if (handbook?.createdByUserId == currentUser.id) {
    userHandbookAccess.isHandbookAdmin = true
  }

  return {
    userAccountType,
    userFolderAccess,
    userHandbookAccess,
  }
}

export default useUser
